








































































































































































import { defineComponent, ref, computed, inject } from '@vue/composition-api';

import { carsDict } from '@/data/car.data';
import { Car } from '@/models/car.model';
import VueRouter from 'vue-router';

export default defineComponent({
  name: 'Car',
  props: {
    id: {
      type: String,
      default: () => ''
    }
  },
  components: {},
  setup: (props, ctx) => {
    const altLayout = ref(false);
    const swapCards = ref(false);

    const currCar = carsDict[props?.id] as Car;
    const currentCar = ref(currCar);

    const carouselMinHeight = computed(() => {
      if (ctx.root.$vuetify.breakpoint.mdAndUp) {
        return `calc(80vh - ${ctx.root.$vuetify.application.top}px)`;
      }

      return 'unset';
    });

    const carouselItems = ref([...currCar?.imgs]);
    const listMode = ref(true);

    const router = inject<VueRouter>('$router');
    const goBack = () => router?.go(-1);

    return {
      altLayout,
      swapCards,
      currentCar,
      carouselMinHeight,
      carouselItems,
      listMode,
      goBack
    };
  }
});
